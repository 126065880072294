<template>
  <div class="tw-flex tw-min-h-screen tw-max-w-none tw-flex-col tw-bg-white">
    <SiteHeader />
    <slot />
    <SiteFooter />
    <GetHelp />
    <AppToast />
  </div>
</template>

<script setup lang="ts">
// Use the composable for dynamic head
const { applyHead } = useDynamicHead()
applyHead()
</script>
